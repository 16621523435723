<template>
  <div class="report">
    <div class="content">
      <div class="report__caption">Сообщить о претензии на лекарственный препарат</div>
    </div>
    <Breadcrumbs :pages="[{ name: 'Сообщить о претензии на лекарственный препарат' }]" />
    <div class="content">
      <div class="report__description">
       Если вы хотите сообщить о претензии в отношении качества или подлинности лекарственного препарата, вы можете обратиться в ООО «АстраЗенека Фармасьютикалз»:
        <br>
        <br>
        Сделать это вы можете по телефону:
        <a href="tel:84957995699">+7 &nbsp;(495)&nbsp;799&#8209;56&#8209;99</a>
        <br>
        <br>Или по электронной почте:
        <a href="mailto:ProductQuality.ru@astrazeneca.com">ProductQuality.ru@astrazeneca.com</a>
        <br>
        <br>
        Почтовый адрес:
        <a target="_blank" href="https://yandex.ru/maps/213/moscow/house/1_y_krasnogvardeyskiy_proyezd_21s2/Z04YcwRkSUwFQFtvfXt1eH9rZA==/?indoorLevel=1&ll=37.533798%2C55.749296&z=17.88">123112, г. Москва, 1-й Красногвардейский проезд, д.21, стр.1., 30 этаж</a>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
export default {
  metaInfo: {
      title: 'Сообщить о претензии на лекарственный препарат',
  },
  name: "ReportDrugClaim",
  components: { Breadcrumbs },
};
</script>

<style lang="scss" scoped>
.report {
  padding-top: 32px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }
  &__caption {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__description {
    margin-top: 64px;
    max-width: 800px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;

    & > a {
      color: #d0006f;
    }

    @media screen and (max-width: 1220px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  .breadcrumbs {
    @media screen and (max-width: 767px) {
      padding-top: 16px;
    }
  }
}
</style>